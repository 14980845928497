import { SESSION_BASE_FRAGMENT } from '@/graphql/_Fragments/Session/Base';
import { POLL_ANSWER_BASE_FRAGMENT } from '@/graphql/_Fragments/PollAnswer/Base';

export const POLL_FULL_FRAGMENT = `
  fragment pollFullFragment on Poll {
    uid
    title
    startTime
    endTime
    pollAnswers(orderBy: displaySequence_asc) {
     ...pollAnswerBaseFragment
    }
    session {
      ...sessionBaseFragment
    }
    answerCountByAnswerId {
      key
      value
    }
    _myAnswer(myUid: "%authUser%")
  }
  ${SESSION_BASE_FRAGMENT}
  ${POLL_ANSWER_BASE_FRAGMENT}
`;
